(function () {
    $(document).ready(function () {
        applicationForm();
        touchEvents();

        if ($('body').hasClass('page__home')) {
            homepage();
        }
    });

    var screens = {
        mobile: {
            min: 0,
            max: 767
        },

        tablet: {
            min: 768,
            max: 991
        },

        desktop: {
            min: 992,
            max: null
        }
    };

    var gridBreakpoint = screens.desktop.min,
        gridBreakpointMax = screens.tablet.max;

    function sectionWidthHeightRatios() {
        var $secs = $('main > section[data-width-height-ratio]');

        $(window).resize(function () {
            $secs.height('auto');

            if (window.innerWidth > gridBreakpoint) {
                $secs.each(function () {
                    var $sec = $(this),
                        ratio = parseFloat($sec.data('width-height-ratio')),
                        minHeight = $sec.width() * ratio;

                    if ($sec.height() < minHeight) {
                        $sec.height(minHeight);
                    }
                });
            }
        }).trigger('resize');
    }

    function homepage() {
        primarySection();
        parallax();
        activeSection();
        sectionSelection();
        sectionWidthHeightRatios();
    }

    function touchEvents() {
        var target;

        $(document)
            .on('touchstart', function (e) {
                target = $(e.target);
            })
            .on('touchend', function () {
                target.blur();
            })
    }

    function primarySection() {
        $('h1').fitText(0.928888);

        $(window).on('resize', function () {
            primarySectionHeight();
        }).trigger('resize');
    }

    function primarySectionHeight() {
        var $el = $('section.primary');

        if ($el.width() > gridBreakpoint) {
            $el.height($el.width() / 1440 * 818 * 0.8); // because bg image is 1440*818
        } else {
            $el.height('auto');
        }
    }

    function parallax() {
        var action = function () {
            var $section = $('section.primary'),
                rect = $section[0].getBoundingClientRect();

            // section.primary sayfadan çıkmışsa işlem yapma
            if (rect.bottom < 0)
                return;

            var y = $(this).scrollTop(),
                diff = y - $section.scrollTop();

            $section.find('>img').css('transform', 'translate3d(0, ' + Math.round(-diff / 5) + 'px, 0)');
        };

        var $win = $(window);

        $win.scroll(function () {
            if ($win.width() > gridBreakpoint) {
                action();
            }
        });
    }

    function sectionSelection() {
        $(document).on('click', 'a[data-section]', function () {
            goToSection($(this).data('section'));
        });
    }

    function activeSection() {
        var $sections = $('main > section'),
            $secSel = $('#section-selection');

        var action = function () {
            var line = window.innerHeight / 2;

            var $section = $sections.filter(function () {
                var rect = this.getBoundingClientRect();

                return rect.top <= line && rect.bottom >= line;
            });

            var index = $sections.index($section);

            $('section.primary .continue-button')[(index == 0 ? 'addClass' : 'removeClass')]('wobble');

            if (index > 0) {
                $section.addClass('is-visible');
            }

            if (!$secSel.find('li:eq(' + index + ').active').length) {
                $secSel.find('.active').removeClass('active');
                $secSel.find('li:eq(' + index + ')').addClass('active');
            }
        };

        $(window).scroll(function () {
            action();
        });
    }

    function goToSection(sectionName) {
        var $sec = $('section.' + sectionName),
            $main = $('html,body');

        if ($sec.height() < window.innerHeight) {
            var windowDiff = (window.innerHeight + 75 - $sec.height()) / 2;

            $main.animate({scrollTop: $sec.offset().top - windowDiff}, 400);
        } else {
            $main.animate({scrollTop: $sec.offset().top}, 400);
        }
    }

    function applicationForm() {
        var $appForm = $('#application-form'),
            $form = $appForm.find('form'),
            $result = $form.find('.result'),
            $submit = $form.find('[type="submit"]');

        $appForm.find('input[name="phone"]').mask("9999 999 99 99", {placeholder:"**** *** ** **"});
        $appForm.find('input[name="identity_number"]').mask("99999999999", {placeholder:"***********"});

        $(document).on('click', '[data-application-form]', function () {
            $appForm.addClass('open');
            $('body').addClass('application-form-open');
        });

        $appForm.on('click', '.close', function () {
            $appForm.removeClass('open');
            $('body').removeClass('application-form-open');
        });

        $submit.data('default-state', $submit.html());

        var submitLoadingState = function () {
            $submit.prop('disabled', true).html('<i class="fa fa-spin fa-spinner"></i>');
        };

        var submitDefaultState = function () {
            $submit.prop('disabled', false).html($submit.data('default-state'));
        };

        var submitDoneState = function () {
            $submit.prop('disabled', true).html('<i class="fa fa-check"></i>');
        };

        var formResultHandler = function (response) {
            $form.find(':input:not([type="submit"])').prop('disabled', false);

            if (response.message) {
                $result.text(response.message);
            }

            if (response.validationErrors) {
                $.each(response.validationErrors, function (key, errors) {
                    var $inp = $form.find('[name="' + key + '"]'),
                        $grp = $inp.parents('.form-group');

                    $grp.addClass('has-error');

                    $.each(errors, function (_k, error) {
                        $inp.after('<span class="help-block error-block">' + error + '</span>');
                    });
                })
            }
        };

        $form.on('submit', function (e) {
            e.preventDefault();

            $form.find('.has-error').removeClass('has-error').find('.error-block').remove();
            $result.removeClass('result-success').removeClass('result-failed').text('');

            // Fetch data before inputs get disabled
            var data = $form.serialize();

            $form.find(':input:not([type="submit"])').prop('disabled', true);
            submitLoadingState();

            $.ajax({
                type: $form.attr('method'),
                url: $form.attr('action'),
                data: data
            })
                .done(function (response) {
                    $result.addClass('result-success');
                    formResultHandler(response);

                    $form.find(':input:not([type="submit"])').prop('disabled', true);
                    submitDoneState();

                    setTimeout(function () {
                        $form.trigger('reset');
                    }, 5000);
                })
                .fail(function (jqXHR) {
                    $result.addClass('result-failed');
                    formResultHandler(jqXHR.responseJSON);

                    submitDefaultState();
                });
        });
    }

})();

(function () {

    if (!$('body').hasClass('page__help'))
        return;

    var $questions = $('#questions');

    $questions.on('click', '.toggler', function () {
        $questions.find('.open').not($(this).parents('li')).removeClass('open').find('.content').slideUp(300);
        $(this).parents('li').toggleClass('open').find('.content').slideToggle(300);
    });

})();